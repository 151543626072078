import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Container from "../components/Container";
import Row from "../components/Row";
import Col from "../components/Col";
import Title from "../components/Title";
import styled from "styled-components";
import { HomeAbout } from "../components/home/HomeAbout";
import FeaturedProjects from "../components/home/FeaturedProjects";
import HomeServices from "../components/home/HomeServices";
import { theme } from "../utils/styled_config";
import Section from "../components/Section";
import { StaticImage } from "gatsby-plugin-image";

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <Section>
        <Container>
          <Row justify="center">
            <CenterCol lg={12}>
              <StaticImage
                src="../images/home-stars-top.png"
                height={103}
                alt="stars"
              />
              <br />
              <br />
              <br />
            </CenterCol>
            <CenterCol lg={9}>
              <Title size={1} lg={"d3"} slim="1">
                We create <Danger>stellar</Danger> software solutions with a{" "}
                <Purple>clear</Purple> and <Info>creative</Info> mindset.
              </Title>
            </CenterCol>
          </Row>
          <br />
          <br />
          <Row justify="between" justifyLg="center">
            <Col col={6} lg="auto">
              <StaticImage
                src="../images/home-stars-left.png"
                height={92}
                alt="stars"
              />
            </Col>
            <CenterCol lg={2} hiddenMobile={true}>
              <StaticImage
                src="../images/scroll.png"
                height={167}
                alt="scroll"
              />
            </CenterCol>
            <Col col={6} lg="auto">
              <StaticImage
                src="../images/home-stars-right.png"
                height={92}
                alt="stars"
              />
            </Col>
          </Row>
        </Container>
      </Section>
      <HomeAbout />
      <FeaturedProjects />
      <HomeServices />
    </Layout>
  );
};

const CenterCol = styled(Col)`
  text-align: center;
`;

const Danger = styled.span`
  color: ${theme.color.danger};
`;

const Purple = styled.span`
  color: ${theme.color.purple};
`;

const Info = styled.span`
  color: ${theme.color.info};
`;

export default IndexPage;
