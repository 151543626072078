import React from "react";
import SectionTitle from "../SectionTitle";
import Section from "../Section";
import Container from "../Container";
import Row from "../Row";
import Col from "../Col";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { BREAKPOINTS } from "../../utils/styled_config";

const HomeServices = () => {
  return (
    <SectionStyled>
      <Container>
        <SectionTitle
          title="Our Services"
          subtitle="Results-driven solutions"
        />

        <Row justify="center">
          <Col xs={6} lg="auto">
            <ServiceIcon>
              <StaticImage
                src="../../images/service-icon-1.png"
                alt="icon"
                height={64}
              />
              <br />
              <br />
              Website Design & Development
            </ServiceIcon>
          </Col>
          <Col xs={6} lg="auto">
            <ServiceIcon>
              <StaticImage
                src="../../images/service-icon-2.png"
                alt="icon"
                height={64}
              />
              <br />
              <br />
              User Experience & User Interface Design
            </ServiceIcon>
          </Col>
          <Col xs={6} lg="auto">
            <ServiceIcon>
              <StaticImage
                src="../../images/service-icon-3.png"
                alt="icon"
                height={64}
              />
              <br />
              <br />
              E-Commerce Platform
            </ServiceIcon>
          </Col>
          <ColSeparator />
          <Col xs={6} lg="auto">
            <ServiceIcon>
              <StaticImage
                src="../../images/service-icon-4.png"
                alt="icon"
                height={64}
              />
              <br />
              <br />
              Mobile Applications
            </ServiceIcon>
          </Col>
          <Col xs={6} lg="auto">
            <ServiceIcon>
              <StaticImage
                src="../../images/service-icon-5.png"
                alt="icon"
                height={64}
              />
              <br />
              <br />
              Internal Custom Systems
            </ServiceIcon>
          </Col>
          <Col xs={6} lg="auto">
            <ServiceIcon>
              <StaticImage
                src="../../images/service-icon-6.png"
                alt="icon"
                height={64}
              />
              <br />
              <br />
              Technology Consultation & UX Audit
            </ServiceIcon>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  );
};

const SectionStyled = styled(Section)`
  padding-bottom: 0;
`;

const ColSeparator = styled.div`
  display: none;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    display: block;
    width: 100%;
  }
`;

const ServiceIcon = styled.div`
  text-align: center;
  font-size: 14px;
  margin: 0 auto 40px;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    margin: 0 auto 64px;
    width: 230px;
    font-size: 18px;
  }
`;

export default HomeServices;
