import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ArrowRightIcon from "./Icons/ArrowRightIcon";

const ProjectThumbnail = ({ title, categories, link, thumbnail }) => {
  return (
    <Project>
      <Link to={link}>
        <GatsbyImage image={getImage(thumbnail)} alt={title} />
      </Link>
      <ProjectTitle>{title}</ProjectTitle>
      <ProjectCategories>{categories}</ProjectCategories>
      <ProjectLink to={link}>
        <LeftSpan>View Project</LeftSpan>
        <ArrowRightIcon />
      </ProjectLink>
    </Project>
  );
};

const Project = styled.div`
  img {
    border-radius: 4px;
  }
`;

const ProjectTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  margin-top: 12px;
`;

const ProjectCategories = styled.div`
  color: #e0e0e0;
`;

const ProjectLink = styled(Link)`
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-weight: bold;

  &:hover {
    color: #ddd;
  }
`;

const LeftSpan = styled.span`
  margin-right: 8px;
`;

export default ProjectThumbnail;
