import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../utils/styled_config";
import Title from "./Title";

const SectionTitle = ({ title, subtitle }) => {
  return (
    <SectionStyled>
      <TitleStyled size={3} lg={2}>
        {title}
      </TitleStyled>
      {subtitle}
    </SectionStyled>
  );
};

const SectionStyled = styled.div`
  text-align: center;
  color: #9e9e9e;
  margin-bottom: 40px;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    margin-bottom: 64px;
  }
`;

const TitleStyled = styled(Title)`
  color: #fff;
  margin-bottom: 4px;
`;

export default SectionTitle;
