import React from "react";
import Container from "../Container";
import Row from "../Row";
import Col from "../Col";
import Paragraph from "../Paragraph";
import Section from "../Section";
import Button from "../Button";
import { StaticImage } from "gatsby-plugin-image";

export const HomeAbout = () => {
  return (
    <Section>
      <Container>
        <Row align="center">
          <Col lg={6} mb={6} mbLg={0}>
            <StaticImage
              src={"../../images/home-about.png"}
              alt="girl typing"
            />
          </Col>
          <Col lg={6}>
            <Paragraph>
              <b>TechKosmo</b> is a software development company that
              specializes in custom digital solutions that are robust, flexible,
              and scalable. This includes the design and development of
              websites, mobile applications, and internal systems.
            </Paragraph>
            <br />
            <Row justify="center" justifyLg="flex-start">
              <Col col="auto">
                <Button to="/about">Our Story</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};
