import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SectionTitle from "../SectionTitle";
import Section from "../Section";
import Container from "../Container";
import Row from "../Row";
import Col from "../Col";
import ProjectThumbnail from "../ProjectThumbnail";
import Button from "../Button";
import styled from "styled-components";
import { BREAKPOINTS } from "../../utils/styled_config";

const FeaturedProjects = () => {
  const feature_projects = useStaticQuery(graphql`
    query FeaturedProjectsQuery {
      allProjectsJson(filter: { featured: { eq: true } }) {
        edges {
          node {
            id
            name
            categories
            slug
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP]
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Section>
      <Container>
        <SectionTitle title="Featured Projects" subtitle="Our recent work" />
        <Row justify="center" mb={10} mbLg="16">
          <Col lg={10}>
            <Row>
              {feature_projects.allProjectsJson.edges.map((p) => (
                <ColWorks sm={6} lg={6} key={p.node.id}>
                  <ProjectThumbnail
                    thumbnail={p.node.thumbnail}
                    title={p.node.name}
                    categories={p.node.categories}
                    link={`/works/${p.node.slug}`}
                  />
                </ColWorks>
              ))}
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col col="auto">
            <Button to="/works">View All Projects</Button>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

const ColWorks = styled(Col)`
  &:nth-child(even) {
    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      padding-top: 80px;
    }
  }

  & + & {
    margin-top: 30px;

    @media screen and (min-width: ${BREAKPOINTS.lg}) {
      margin-top: 0;
    }
  }
`;

export default FeaturedProjects;
